import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/event-schema'
const { eventSchema: schema } = schemaSettings
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const service = apiClient.service('events')
const profilesService = apiClient.service('profiles')

export default ({ name }) => ({
  state: {
    ...createCrudState({ entityType: 'events', storePrefix: name }),
    upload: { created: 0, updated: 0, loading: false, success: null, error: '' },
    calendar: {
      initialView: 'dayGridMonth',
    },
    calendarThemes: [],
    calendarThemeTypes: [],
    calendarPrint: {
      theme: null,
      paperSizeName: 'Letter',
      fontSizeName: '3 - Standard',
      paperOrientation: 'landscape',
      printSingleLine: true,
      hideEventDescription: false,
      hideEventType: false,
      hideLocation: false,
      showBirthdays: false,
      eventCalendars: [''],
      weeklyCalendarLayout: 'list',
      dayPrintInterval: null,
      levelsOfCare: [],
      showTransportRequiredOnly: false,
    },
    birthdays: [],
  },
  sequences,
  providers: createProviders({ name, service, profilesService, schema }),
  catch: [
    redirectUnauthorized(),
    [
      Error,
      showFeedbackFromError({ title: 'We encountered an error' }),
      ({ props: { error, errorPath = name }, store }) => {
        store.set(state`errors.${errorPath}`, error)
      },
    ],
  ],
})
