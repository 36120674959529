import { compose, path, pluck } from 'ramda'
import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'

import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { loadProfilesSequence } from '../profiles/factories'

const loadProfiles = loadProfilesSequence(compose(pluck('profile'), path(['props', 'entities', 'eventRsvps'])), 'profileIds')

export const find = [
  actions.find,
  saveEntities(),
  loadProfiles,
  // probably might take an event id, need to customize to work on that input
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
  loadProfiles,
]

export const get = [
  //
  actions.get,
  saveEntities(),
  loadProfiles,
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  when(props`values.cancelled`),
  {
    true: showFeedback({ title: `Event RSVP cancelled`, type: 'success' }),
    false: showFeedback({ title: `Event RSVP saved`, type: 'success' }),
  },
  when(state`route.query.eventInstance`),
  {
    true: [
      //
      set(props`params.eventInstance`, state`route.query.eventInstance`),
      route.toEventRsvps,
    ],
    false: [
      //
      route.toEventInstances,
    ],
  },
]

export const saveWithoutFeedback = [
  //
  actions.save,
  saveEntities(),
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Event RSVP deleted', type: 'success' }),
  findForList,
]
