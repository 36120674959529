const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
  eventInstance: yup.mixed(),
  rsvpCount: yup.number(),
  profile: yup.mixed(),
  count: yup.number().min(0),
  cancelled: yup.boolean(),
})

const eventRsvpSchemaPartial = yup.object().shape(shape)
const eventRsvpSchema = eventRsvpSchemaPartial.shape(requiredFields(['_id'])(shape))

module.exports = { eventRsvpSchema, eventRsvpSchemaPartial }
